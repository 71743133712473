import React from "react";
import _ from "lodash";

import { Layout } from "../components/index";
import { markdownify, getPages, Link, safePrefix } from "../utils";

export default class Posts extends React.Component {
  render() {
    const lang = _.get(this.props, "pageContext.frontmatter.lang");
    let newsPath = "/actus";
    if (lang === "en") {
      newsPath = "/en/news";
    }
    if (lang === "es") {
      newsPath = "/es/noticias";
    }
    return (
      <Layout {...this.props} lang={lang}>
        <section id="main" className="wrapper">
          <div className="inner">
            <header className="major">
              <h1>{_.get(this.props, "pageContext.frontmatter.title")}</h1>
              {markdownify(
                _.get(this.props, "pageContext.frontmatter.subtitle")
              )}
            </header>
            <div className="posts">
              {_.map(
                _.orderBy(
                  getPages(this.props.pageContext.pages, newsPath),
                  "frontmatter.date",
                  "desc"
                ),
                (post, post_idx) => (
                  <section
                    key={post_idx}
                    className="post"
                    style={{
                      "--nb-col": _.get(
                        this.props,
                        "pageContext.frontmatter.columns"
                      ),
                    }}
                  >
                    {_.get(post, "frontmatter.alt_img") && (
                      <Link
                        to={safePrefix(_.get(post, "url"))}
                        className="image"
                      >
                        <img
                          src={safePrefix(_.get(post, "frontmatter.alt_img"))}
                          alt=""
                        />
                      </Link>
                    )}
                    <div className="content">
                      <h3>{_.get(post, "frontmatter.title")}</h3>
                      {_.get(post, "frontmatter.date") && (
                        <p className="post__date">
                          {_.get(post, "frontmatter.date")}
                        </p>
                      )}
                      {markdownify(_.get(post, "frontmatter.excerpt"))}
                      <Link
                        to={safePrefix(_.get(post, "url"))}
                        className="button"
                      >
                        {_.get(this.props, "pageContext.frontmatter.ctaLabel")}
                      </Link>
                    </div>
                  </section>
                )
              )}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
